var jar = require('jarvanillajs');

/**
 * App Main
 * @returns {Promise<void>}
 */
const main = async () => {
    // Import our CSS
    const Styles = await import(
        /* webpackChunkName: "styles" */
        '../css/app.pcss'
    );

    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        delimiters: ['${', '}'],
        components: {
            // 'confetti': () => import(/* webpackChunkName: "confetti" */ '../vue/Confetti.vue'),
            // 'mglmap': () => import(/*webpackChunkName: "mapbox" */ '../vue/Mapbox.vue'),
            // 'mglmapselector': () => import(/*webpackChunkName: "mapboxselector" */ '../vue/MapboxSelector.vue'),
            'hamburger': () => import(/* webpackChunkName : "hamburger" */ '../vue/Hamburger.vue'),
            'command': () => import(/* webpackChunkName : "command" */ '../vue/Command.vue'),
            'addressedit': () => import(/* webpackChunkName: "AddressEdit" */ '../vue/AddressEdit.vue'),
            'pricing': () => import(/* webpackChunkName: "Pricing" */ '../vue/Pricing.vue'),
        },
        data: {
        },
        methods: {
        },
        mounted() {
        },
    });

    return vm;
};

/**
 * Execute App main async function
 */
main().then( (vm) => {
});

/**
 * Import aFarkas' lazysizes plugin
 * @returns {Promise<void>}
 */
const lazyLoading = async () => {
    const lazySizes = await import (
        /* webpackChunkName: "lazysizes" */
        'lazysizes'
    );
}

/**
 * Adjust all active Flickity sizes to the changed viewport dimensions.
 */
function resizeFlickity() {
    let carousels = document.querySelectorAll('.flickity-enabled');
    if (carousels) {
        for (let i = carousels.length - 1; i >= 0; i--) {
            let carousel = carousels[i];
            let flkty = new Flickity( carousel );

            flkty.resize();
        }
    }
}

/**
 * Things to do on saving the cookie settings
 * @return {[type]} [description]
 */
function acceptCookieClick(element) {
    jar.set(
        appId + "_cookies_accepted",
        'true',
        1000 * 60 * 60 * 24 * 30
    );
}

function showCookieSettings() {
    const cookieContainer = document.querySelector('.cookies-container');
    if (cookieContainer) {
        cookieContainer.classList.add('visible');
    }
}

function listenForCookieAccept() {
    let cookieAcceptButton = document.getElementById('accept-cookies');
    if (cookieAcceptButton) {
        cookieAcceptButton.addEventListener('click', function(e){
            acceptCookieClick(e.target);
            hideCookiesContainer();
        })
    }
}

function hideCookiesContainer() {
    let cookiesContainer = document.querySelector('.cookies-container');
    if (cookiesContainer) {
        cookiesContainer.classList.remove('is-open');
    }
}

function showCookiesContainer() {
    let cookiesContainer = document.querySelector('.cookies-container');
    if (cookiesContainer) {
        cookiesContainer.classList.add('is-open');
    }
}

function checkCookiesAccepted() {
    let cookiesAccepted = jar.get(appId + "_cookies_accepted");
    if (!cookiesAccepted) {
        showCookiesContainer();
    }
}

function toggleOpen(target) {
    target.classList.toggle('is-open');
}

window.addEventListener('load', function() {
    lazyLoading();
    resizeFlickity();
    listenForCookieAccept();
    checkCookiesAccepted();
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
